export function formatDateRange(startDateString: string, endDateString?: string | null) {
  const startDate = new Date(startDateString);
  const endDate = endDateString ? new Date(endDateString) : null;

  const startMonth = startDate.toLocaleDateString('en-US', { month: 'long' });
  const endMonth = endDate?.toLocaleDateString('en-US', { month: 'long' });
  const startDay = startDate.getDate();
  const endDay = endDate?.getDate();
  const startYear = startDate.getFullYear();
  const endYear = endDate?.getFullYear();

  if (!endDate) {
    return `${startMonth} ${startDay}, ${startYear}`;
  }

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      return `${startMonth} ${startDay} - ${endDay}, ${startYear}`;
    } else {
      return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
    }
  }

  return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
}
