'use client';

import { Button, FieldError, Form, Input, Label, TextField } from 'react-aria-components';

import { NewsletterSignUp as NewsletterSignUpProps } from '@/_lib/types';
import { useActionState } from '@/_hooks/use-action-state';
import { addNewsletterSubscriber } from '@/_services/add-newsletter-subscriber';

export type NewsletterSignupState = 'Initial' | 'Invalid' | 'Successful' | 'Failure';

export default function NewsletterSignUp({ heading }: NewsletterSignUpProps) {
  const [, submitAction, isPending] = useActionState<NewsletterSignupState, FormData>(
    addNewsletterSubscriber,
    'Initial',
  );

  return (
    <section>
      <h2>{heading}</h2>
      <Form action={submitAction}>
        <div style={{ display: 'flex', gap: 32 }}>
          <TextField autoComplete="name" name="name" type="text" isRequired>
            <Label>Name:</Label>
            <Input />
            <FieldError>Name is required.</FieldError>
          </TextField>
          <TextField autoComplete="email" name="email" type="email" isRequired>
            <Label>Email:</Label>
            <Input />
            <FieldError>Email is required.</FieldError>
          </TextField>

          <Button type="submit" isDisabled={isPending}>
            Submit
          </Button>
        </div>
      </Form>
    </section>
  );
}
