'use client';

import Link from 'next/link';

import SanityImage from '@/_components/Global/SanityImage/SanityImage';
import { ALL_PEOPLE_QUERYResult } from '@/_lib/types';
import { useState } from 'react';

type TeamDirectoryClientProps = {
  data: ALL_PEOPLE_QUERYResult;
};

export default function TeamDirectoryClient({ data }: TeamDirectoryClientProps) {
  const [search, setSearch] = useState('');

  if (!data) {
    return null;
  }

  return (
    <section>
      <label>
        Search:{' '}
        <input onChange={(e) => setSearch(e.target.value.trim().toLowerCase())} type="search" />
      </label>
      <nav aria-label="Team directory">
        <ul
          style={{
            display: 'grid',
            gap: 32,
            gridTemplateColumns: `repeat(auto-fill, minmax(280px, 1fr))`,
            listStyleType: 'none',
            margin: 0,
            padding: 0,
          }}
        >
          {data
            .filter((d) => d.slug.current)
            .filter(
              (d) =>
                !search ||
                d.title.toLowerCase().includes(search) ||
                d.email.toLowerCase().includes(search) ||
                d.jobTitle.toLowerCase().includes(search) ||
                d.location.toLowerCase().includes(search),
            )
            .map((d) => {
              return (
                <li key={d._id}>
                  <Link href={d.slug.current}>
                    <SanityImage
                      alt={`Profile image for ${d.title}`}
                      image={d.rollupImage}
                      sizes="(max-width: 48rem) 100vw, (max-width: 60rem) 33vw, 300px"
                      width={300}
                    />
                    <br />
                    <span>{d.title}</span>
                  </Link>
                </li>
              );
            })}
        </ul>
      </nav>
    </section>
  );
}
