import { useState, useTransition } from 'react';

export function useActionState<State, Payload>(
  action: (state: Awaited<State>, payload: Payload) => State | Promise<State>,
  initialState: Awaited<State>,
  permalink?: string,
): [state: Awaited<State>, dispatch: (payload: Payload) => void, isPending: boolean] {
  const [state, setState] = useState<Awaited<State>>(initialState);
  const [isPending, startTransition] = useTransition();

  const submitAction = async (payload: Payload) => {
    startTransition(async () => {
      const _state = await action(state, payload);
      setState(_state);
    });
  };

  return [state, submitAction, isPending];
}
