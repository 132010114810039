'use client';

import Link from 'next/link';
import { useState } from 'react';

import { ALL_INSIGHTS_ORDERED_BY_DATEResult } from '@/_lib/types';
import { formatDate } from '@/_lib/format-date';
import SanityImage from '../../Global/SanityImage/SanityImage';

type InsightsLightClientProps = {
  data: ALL_INSIGHTS_ORDERED_BY_DATEResult;
};

export default function InsightsListClient({ data }: InsightsLightClientProps) {
  const [search, setSearch] = useState('');

  if (!data) {
    return null;
  }

  return (
    <section>
      <label>
        Search:{' '}
        <input onChange={(e) => setSearch(e.target.value.trim().toLowerCase())} type="search" />
      </label>

      <nav aria-label="Latest insights">
        <ul
          style={{
            display: 'grid',
            gap: 32,
            gridTemplateColumns: `repeat(auto-fill, minmax(280px, 1fr))`,
            listStyleType: 'none',
            margin: 0,
            padding: 0,
          }}
        >
          {data
            .filter(
              (d) =>
                !search ||
                d.title.toLowerCase().includes(search) ||
                d.tags?.map((t) => t.name.toLowerCase()).includes(search),
            )
            .map((d) => {
              return (
                <li key={d._id} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Link href={d.slug.current}>
                    <SanityImage
                      alt=""
                      image={d.rollupImage}
                      sizes="(max-width: 48rem) 100vw, (max-width: 60rem) 33vw, 300px"
                      width={300}
                    />
                    <span>{d.title}</span>
                  </Link>
                  <time dateTime={d.datePublished}>{formatDate(d.datePublished)}</time>
                </li>
              );
            })}
        </ul>
      </nav>
    </section>
  );
}
