'use client';

import Link from 'next/link';
import { useState } from 'react';
import { Button, FieldError, Form, Input, Label, TextArea, TextField } from 'react-aria-components';

import { PAGE_QUERY_FILTER_SLUGResult } from '@/_lib/types';
import { useActionState } from '@/_hooks/use-action-state';
import { sendContactEmail } from '@/_services/send-contact-email';

export type ContactFormState = 'Initial' | 'Invalid' | 'Successful' | 'Failure';

type ContactFormProps = Extract<
  NonNullable<NonNullable<PAGE_QUERY_FILTER_SLUGResult>['pageBuilder']>[number],
  { _type: 'contactForm' }
>;

const COPY_BUTTON_DELAY = 3000;

export default function ContactForm({
  heading,
  ourEmailLabel,
  contactEmail,
  copyButton,
  copiedButton,
  nameLabel,
  nameRequiredMessage,
  emailLabel,
  emailRequiredMessage,
  messageLabel,
  messageRequiredMessage,
  sendButton,
  successPage,
}: ContactFormProps) {
  const [copied, setCopied] = useState(false);
  const [, submitAction, isPending] = useActionState<ContactFormState, FormData>(
    sendContactEmail,
    'Initial',
  );

  return (
    <section style={{ maxWidth: 400 }}>
      {heading && <h2>{heading}</h2>}

      {contactEmail && (
        <div>
          <span>{ourEmailLabel}:</span>
          <br />
          <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
          <br />
          <button
            onClick={() => {
              navigator.clipboard.writeText(contactEmail);

              setCopied(true);
              setTimeout(() => setCopied(false), COPY_BUTTON_DELAY);
            }}
            type="button"
          >
            {copied ? copiedButton : copyButton}
          </button>
        </div>
      )}

      <Form action={submitAction}>
        <input name="redirectUrl" type="hidden" value={successPage?.slug.current} />

        <TextField
          autoComplete="name"
          name="name"
          type="text"
          isRequired
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Label>{nameLabel}:</Label>
          <Input />
          <FieldError>{nameRequiredMessage}</FieldError>
        </TextField>
        <TextField
          autoComplete="email"
          name="email"
          type="email"
          isRequired
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Label>{emailLabel}:</Label>
          <Input />
          <FieldError>{emailRequiredMessage}</FieldError>
        </TextField>
        <TextField
          name="message"
          type="textarea"
          isRequired
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Label>{messageLabel}:</Label>
          <TextArea />
          <FieldError>{messageRequiredMessage}</FieldError>
        </TextField>

        <Button type="submit" isDisabled={isPending}>
          {sendButton}
        </Button>
      </Form>
    </section>
  );
}
