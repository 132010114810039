'use client';

import Image, { ImageProps } from 'next/image';
import { getImageDimensions } from '@sanity/asset-utils';

import { urlFor } from '@/_lib/image';

type Props = Omit<ImageProps, 'src'> & {
  image: any;
};

export default function SanityImage({ image, alt, fill, height, width, ...props }: Props) {
  if (!image) {
    return null;
  }

  const dimensions = getImageDimensions(image);
  const blurDataURL = image.asset?.metadata?.lqip;

  let _height = undefined;
  let _width = undefined;

  if (width && !height) {
    _width = width;

    if (dimensions?.height) {
      _height = Math.round((dimensions.height / dimensions.width) * parseInt(width.toString()));
    }
  } else if (!width && height) {
    _height = height;

    if (dimensions?.width) {
      _width = Math.round((dimensions.width / dimensions.height) * parseInt(height.toString()));
    }
  } else {
    _height = dimensions?.height;
    _width = dimensions?.width;
  }

  return (
    <Image
      alt={alt}
      blurDataURL={blurDataURL}
      fill={fill}
      height={fill ? undefined : _height}
      loader={({ width, quality = 100 }) =>
        urlFor(image).auto('format').width(width).quality(quality).url()
      }
      placeholder={blurDataURL ? 'blur' : undefined}
      src={urlFor(image).url()}
      width={fill ? undefined : _width}
      {...props}
    />
  );
}
